import { memo, useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getChatData } from "../Chat"
import { setActiveChat } from "../../Stores/UI"
import { PageHandle } from "../Page"
import { showUserProfile } from "../Pages/UserProfile"
import { Icon, Profile } from "../common"

function ChatInfo() {

    const page = useSelector((state) => state.ui.value.page)
    const showPage = useSelector((state) => state.ui.value.showPage)
    const activeChat = useSelector((state) => state.ui.value.activeChat)

    const dispatch = useDispatch()

    const showChatProfile = useCallback((e) => {
        if (e.target.closest('.BackArrow'))
            return
        if (activeChat.type === 'private') {
            showUserProfile(activeChat.to, dispatch)
        } else
            PageHandle(dispatch, 'ChatProfile', '')
    }, [page, showPage, activeChat])

    const chatInfoSubtitle = () => {
        switch (activeChat.type) {
            case 'private':
                return 'Online'
            case 'group':
            default:
                return activeChat.members.length > 1 ? activeChat.members.length + ' members' : '1 member'
        }
    }

    return <div className="ChatInfo" onClick={showChatProfile}>
        <div className="info">
            <Icon name="arrow_back" className="BackArrow" onClick={() => dispatch(setActiveChat())} />
            <div className="meta"><Profile name={activeChat.firstname} id={getChatData(activeChat)._id} /></div>
            <div className="body">
                <div className="title">{activeChat.firstname}</div>
                <div className="subtitle">{chatInfoSubtitle()}</div>
            </div>
        </div>
        <div className="actions">
            <div className="Menu">
                <Icon name="more_vert" />
            </div>
        </div>
    </div>
}

export default memo(ChatInfo)