import { memo, useContext, useEffect, useRef, useState } from "react"
import { EmojiConvertor } from "emoji-js";
import { UserContext } from "../Auth/Auth";

function MessageText({ data, isInChat = false, includeFrom = false }) {
    const [text, setText] = useState(data.message)

    const spanRef = useRef();

    const User = useContext(UserContext)

    useEffect(() => {
        if (Object.keys(data).length !== 0) {
            setText(getMessageText(data, User._id, isInChat, includeFrom))
        }
    }, [data.message])

    return <span dangerouslySetInnerHTML={{ __html: text }}></span>
    // return <span ref={spanRef}>{text}</span>
}

export const getMessageText = (data, userId, isInChat = false, includeFrom = false) => {
    if (data.message) {
        const emoji = new EmojiConvertor()

        emoji.replace_mode = 'img';
        emoji.img_sets.apple.path = 'https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-apple/15.0.1/img/apple/64/'
        emoji.allow_native = true;
        emoji.include_title = true
        var output = emoji.replace_colons(data.message);
        output = (includeFrom ? data.from.firstname + ': ' : '') + data.message
        if (isInChat)
            output = output.replace(/(https?:\/\/((\d{1,3}\.){3}\d{1,3}|\b[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})(:\d+)?(\/[^\s]*)?)|([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}(\/[^\s]*)?(?<!\.)\b(:\d+)?)/gi, function (x) {
                var href = x;
                if (!x.startsWith('http') && !x.startsWith('https')) {
                    href = 'https://' + x
                }
                return `<a href="${href}" target="_blank">${x}</a>`;
            })
        // output = new DOMParser().parseFromString(output, "text/html").documentElement.textContent;
        return output
    } else if (!isInChat) {
        if (data.type === 'media') {
            return (includeFrom ? data.from.firstname + ': ' : '') + 'Media' // TODO : Set it to Photo, Video, etc
        } else if (data.type === 'call') {
            var output = ''
            switch (data.call?.status) {
                case 'declined':
                    output = 'Declined Call'
                    break;
                case 'answered':
                default:
                    if (data.from._id === userId)
                        output = 'Outgoing Call'
                    else
                        output = 'Incoming Call'
                    break;
            }
            return (includeFrom ? data.from.firstname + ': ' : '') + output
        }
    }
}

export default memo(MessageText)