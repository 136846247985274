import { forwardRef, memo, useContext, useEffect, useRef, useState } from "react";
import Message from "./Message";
import { useDispatch, useSelector } from "react-redux";
import { setMessages } from "../Stores/Messages";
import { handleGoToMessage, handlePinMessage, handlePinnedMessage } from "../Stores/UI";
import MessagesLoading from "../UI/MessagesLoading";
import { socket } from "../../App";
import { AuthContext, UserContext } from "../Auth/Auth";
import { getMessageText } from "./MessageText";
import { goToMessage } from "./Home";

const Messages = forwardRef(({ MessagesRef }, ref) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [messagesRenderCount, setMessagesRenderCount] = useState(20)

    const Auth = useContext(AuthContext)
    const User = useContext(UserContext)

    const messages = useSelector((state) => state.messages.value)
    const activeChat = useSelector((state) => state.ui.value.activeChat)
    const _goToMessage = useSelector((state) => state.ui.value.goToMessage)

    const dispatch = useDispatch()

    const isLoading = useRef(false)
    const autoScroll = useRef(false);
    const scrollToBottom = document.querySelector('.scrollToBottom')
    const BottomRef = document.querySelector('.bottom')

    const onGetMessages = (response) => {
        if (response.ok) {
            socket.emit('UpdateMessageSeen', { token: Auth.authJWT, message: response.data[response.data.length - 1] })
            setIsLoaded(true)
            setTimeout(() => {
                MessagesRef.current.scroll({ left: 0, top: MessagesRef.current.scrollHeight + 3000, behavior: "instant" })
            }, 40)
            if (messages[activeChat._id]?.length !== response.data?.length) {
                dispatch(setMessages({ chatId: activeChat._id, messages: response.data }))
                response.data?.filter((item) => item.pin).map((message) => dispatch(handlePinMessage({ title: 'Pinned Message', subtitle: getMessageText(message, User._id), messageId: message._id })))
                requestAnimationFrame(() => {
                    MessagesRef.current.scroll({ left: 0, top: MessagesRef.current.scrollHeight, behavior: "instant" })
                })
            } else {
                messages[activeChat._id]?.filter((item) => item.pin).map((message) => dispatch(handlePinMessage({ title: 'Pinned Message', subtitle: getMessageText(message, User._id), messageId: message._id })))
            }
        }
    }

    const handleScrollToBottom = () => {
        MessagesRef.current.scroll({ left: 0, top: MessagesRef.current.scrollHeight, behavior: "smooth" })
    }

    useEffect(() => {
        if (messages[activeChat?._id]?.length && autoScroll.current) {
            handleScrollToBottom()
        }
    }, [messages[activeChat?._id]?.length]) // Scroll to Bottom on ReceiveMessage and SendMessage

    useEffect(() => {
        if (activeChat) {
            setIsLoaded(false)
            setMessagesRenderCount(20)
            if (messages[activeChat._id]?.length > 0) {
                isLoading.current = false
                requestAnimationFrame(() => {
                    MessagesRef.current.scroll({ left: 0, top: MessagesRef.current.scrollHeight, behavior: "instant" })
                })
            }
            dispatch(handlePinnedMessage())

            socket.emit('GetMessages', { token: Auth.authJWT, chatId: activeChat._id })
            socket.on('GetMessages', onGetMessages)

            document.querySelector('.scrollToBottom').style.bottom = document.querySelector('.bottom').clientHeight + 8 + 'px'

            return () => socket.off('GetMessages', onGetMessages)
        }
    }, [activeChat]) // Get Messages on activeChat Changed

    const onScrollMessages = () => {
        if (document.querySelector('.scrollToBottom')) {
            if (Math.abs(MessagesRef.current.scrollHeight - MessagesRef.current.clientHeight - MessagesRef.current.scrollTop) > 30
                || 0 > MessagesRef.current.scrollTop) {
                document.querySelector('.scrollToBottom').classList.remove('hidden')
                document.querySelector('.scrollToBottom').style.bottom = document.querySelector('.bottom').clientHeight + 8 + 'px'
                autoScroll.current = false
            } else if (!document.querySelector('.scrollToBottom').classList.contains('hidden')) {
                document.querySelector('.scrollToBottom').classList.add('hidden')
                autoScroll.current = true
            }

            if (messages[activeChat?._id]?.length > messagesRenderCount && MessagesRef.current.scrollTop < 1) {
                console.log('message render count increase')
                MessagesRef.current.scroll({ left: 0, top: 1, behavior: "instant" })
                setMessagesRenderCount(messages[activeChat?._id]?.length < messagesRenderCount * 2 ? messages[activeChat?._id]?.length : messagesRenderCount * 2)
            }
        }
    }

    useEffect(() => {
        if (activeChat && messages[activeChat?._id]?.length && isLoading.current) {
            MessagesRef.current.scroll({ left: 0, top: MessagesRef.current.scrollHeight, behavior: "instant" })
            isLoading.current = false
        }
    }, [activeChat, messages[activeChat?._id]]) // Scroll to Bottom on Load

    useEffect(() => {
        if (_goToMessage) {
            const index = messages[activeChat?._id].indexOf(messages[activeChat?._id].filter((item) => item._id === _goToMessage)[0])

            if (messages[activeChat?._id].length - index < messagesRenderCount) {
                goToMessage(_goToMessage)
            } else {
                setMessagesRenderCount(messages[activeChat?._id].length - index)
                setTimeout(() => {
                    goToMessage(_goToMessage)
                }, 40);
            }
            dispatch(handleGoToMessage())
        }
    }, [_goToMessage])

    console.log('Messages Rerender')

    return <div className="Messages scrollable" ref={MessagesRef} onScroll={onScrollMessages}>
        {messages[activeChat._id] ? <>
            {!isLoaded && <div className="loading">
                {<MessagesLoading />}
            </div>
            }
            {messages[activeChat._id].slice(Math.max(messages[activeChat._id].length - messagesRenderCount, 0)).map((item, index) => (
                <Message key={item?._id} data={item} prevMsgFrom={messages[activeChat._id][messages[activeChat._id].length - messagesRenderCount + index - 1]?.fromId} prevMsgDate={messages[activeChat._id][messages[activeChat._id].length - messagesRenderCount + index - 1]?.date} nextMsgFrom={messages[activeChat._id][messages[activeChat._id].length - messagesRenderCount + index + 1]?.fromId} />
            ))}
        </> :
            (<div className="loading">
                {<MessagesLoading />}
            </div>)}
    </div>

})

export default memo(Messages)