import { useDispatch, useSelector } from "react-redux"
import { Icon, Profile } from "./common"
import { memo } from "react"
import { handleMediaPreviewClose } from "../Stores/UI"

const MediaPreview = () => {
    const data = useSelector((state) => state.ui.value.mediaPreview)

    const dispatch = useDispatch()

    const getMediaLayout = () => {
        switch (data.media[0].fileType) {
            case 'image':
                return <img src={data.mediaSrc} width={calculateMediaDimensions(data.media[0].width, data.media[0].height).width} />
            default:
                if (data.media[0].videoCodec) {
                    return <video src={data.mediaSrc} width={calculateMediaDimensions(data.media[0].width, data.media[0].height).width} autoPlay controls />
                }
                return;
            // return <Document ref={image} path={media[0].filePath} isLoaded={isLoaded} setIsLoaded={setIsLoaded} setProgress={setProgress} />
        }
    }

    const calculateMediaDimensions = (width, height) => {
        const aspectRatio = height / width;
        // console.log(getMaxMessageWidthRem())
        const availableWidthRem = window.innerWidth;
        const calculatedWidth = Math.min(width, availableWidthRem);
        const availableHeight = window.innerHeight - (8 * 16);
        const calculatedHeight = Math.round(calculatedWidth * aspectRatio);

        if (calculatedHeight > availableHeight) {
            return {
                width: Math.round(availableHeight / aspectRatio),
                height: availableHeight,
            };
        }

        return {
            height: calculatedHeight,
            width: calculatedWidth,
        };
    }

    return <>
        <div className="bg animate" style={{ backgroundColor: '#000000dd' }} onClick={() => dispatch(handleMediaPreviewClose())}></div>
        <div className="MediaPreview animate">
            <div className="Topbar">
                <div className="SenderInfo">
                    <div className="meta"><Profile name={data.message.from.firstname} id={data.message.from._id} /></div>
                    <div className="body">
                        <div className="title">{data.message.from.firstname}</div>
                        <div className="subtitle">Yesterday, 15:19</div>
                    </div>
                </div>
                <div className="Action">
                    <Icon name="close" onClick={() => dispatch(handleMediaPreviewClose())} />
                </div>
            </div>
            <div className="Content" onClick={() => dispatch(handleMediaPreviewClose())}>
                <div className="Media">
                    {getMediaLayout()}
                </div>
            </div>
        </div>
    </>
}

export default memo(MediaPreview)