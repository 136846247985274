import { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Icon, Profile } from "./common";
import { AuthContext, UserContext } from "../Auth/Auth";
import Page, { PageClose, PageHandle, PageHeader, SubPage, pageClose } from "./Page";
import DropdownMenu from "../UI/DropdownMenu";
import { handlePageClose, handleTopbarTitleChange } from "../Stores/UI";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "../UI/MenuItem";
import Menu from "../UI/Menu";
import SettingsGeneral from "./Pages/Settings/General";
import Transition from "./Transition";
import SettingsPrivacy from "./Pages/Settings/Privacy";
import SettingsVoiceVideo from "./Pages/Settings/VoiceVideo";
import SettingsDevices from "./Pages/Settings/Devices";
import { socket } from "../../App";

function Settings() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [usernameLoading, setUsernameLoading] = useState(false)
    const [username, setUsername] = useState('')

    const dispatch = useDispatch()
    const User = useContext(UserContext)
    const Auth = useContext(AuthContext)

    const page = useRef()
    const usernameMenu = useRef()

    const subPage = useSelector((state) => state.ui.value.subPage)

    const logOut = () => {
        socket.emit('TerminateSession', { token: Auth.authJWT, session: Auth.authKey })
        socket.on('TerminateSession', (response) => {
            if (response.ok) {
                localStorage.setItem("authState", 'Welcome');
                localStorage.setItem("auth_key", '');
                Auth.setAuthState("Welcome");
                socket.off('TerminateSession')
            }
        })
    }

    const SetUsername = useCallback(() => {
        setUsernameLoading(true)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setIsLoaded(true)
        }, 70);
    }, [])

    useEffect(() => {
        setUsername(User.username)
    }, [User.username])

    const getSubPageLayout = useCallback(() => {
        console.log(subPage)
        switch (subPage[0]?.page) {
            case 'General':
                return <SettingsGeneral />
            case 'Privacy':
                return <SettingsPrivacy />
            case 'Devices':
                return <SettingsDevices />
            case 'VoiceVideo':
                return <SettingsVoiceVideo />
            default:
                break;
        }
    }, [subPage])

    return <>
        <div className={"Settings" + (!isLoaded ? ' fadeThrough' : '') + (subPage[0] ? ' pushUp' : '')} ref={page}>
            <PageHeader>
                <div><Icon name="arrow_back" className="backBtn" onClick={() => PageClose(dispatch)} /></div>
                <div className="Title"><span>Settings</span></div>
                <div className="Meta">
                    <Menu icon="more_vert">
                        <DropdownMenu className="top right withoutTitle">
                            <MenuItem icon="edit" title="Edit Name" onClick={() => { }} />
                            <MenuItem icon="logout" className="danger" title="Log Out" onClick={logOut} />
                        </DropdownMenu>
                    </Menu>
                </div>
            </PageHeader>
            <div className="section Info">
                <div className="User">
                    <Profile name={User.firstname} id={User._id} />
                    <div className="name">{User.firstname} {User.lastname}</div>
                </div>
                <div className="Items">
                    <div className="Item"><Icon name="phone" /><span>+{User.countryCode} {User.phone}</span></div>
                    <Menu ref={usernameMenu} animateWidth={false} closeManually custom={
                        <div className="Item"><Icon name="alternate_email" /><span>{User.username ?? 'Username'}</span></div>}>
                        <DropdownMenu className="CustomMenu Horizontally">
                            <div className="Item">
                                <Icon name="alternate_email" />
                                <input type="text" placeholder="Username" value={username} onInput={(e) => setUsername(e.value)} />
                            </div>
                            <div className="flex">
                                <MenuItem title="Cancel" icon="close" onClick={() => usernameMenu.current.handleCloseMenu()} />
                                <hr />
                                <MenuItem className={usernameLoading ? 'Loading' : ''} title="Apply" icon="done" onClick={() => SetUsername()} />
                            </div>
                        </DropdownMenu>
                    </Menu>
                    <div className="Item"><Icon name="info" /><span>{User.bio}</span></div>
                </div>
            </div>
            <div className="section SettingsItems">
                <div className="Items">
                    <div className="Item" onClick={() => PageHandle(dispatch, 'General', 'General', true)}><Icon name="settings" /><span>General</span></div>
                    <div className="Item" onClick={() => PageHandle(dispatch, 'Privacy', 'Privacy and Security', true)}><Icon name="lock" /><span>Privacy and Security</span></div>
                    <div className="Item"><Icon name="database" /><span>Storage</span></div>
                    <div className="Item"><Icon name="chat" /><span>Chat Settings</span></div>
                    <div className="Item" onClick={() => PageHandle(dispatch, 'Devices', 'Devices', true)}><Icon name="devices" /><span>Devices</span></div>
                </div>
            </div>
            <div className="AppVersion">My App Web v0.5.2</div>
        </div>
        <Transition state={subPage[0]}><SubPage>{getSubPageLayout()}</SubPage></Transition>
    </>
}

export default memo(Settings)