import { useContext, useRef, useState } from "react";
import { AuthContext } from "./Auth";

export default function Password() {
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");

    const passwordTextField = useRef();
    const repeatPasswordTextField = useRef();

    const Auth = useContext(AuthContext);

    const setPasswordHandler = () => {
        if (password !== null && password !== '') {
            if (password.length >= 8) {
                if (password === repeatPassword) {
                    Auth.setAuthPassword(password);
                    Auth.setAuthState("Register");
                } else {
                    passwordTextField.current.querySelector("label").innerHTML =
                        "Password does not match";
                    passwordTextField.current.classList.add("error");
                    repeatPasswordTextField.current.classList.add("error");
                }
            } else {
                passwordTextField.current.querySelector("label").innerHTML =
                    "Your password must be at least 8 characters";
                passwordTextField.current.classList.add("error");
            }
        } else {
            passwordTextField.current.querySelector("label").innerHTML =
                "This field is required";
            passwordTextField.current.classList.add("error");
        }
    };

    return (
        <div className="Password">
            <div className="content">
                <div className="title">Set a Password</div>
                <p className="subtitle">
                    In this step, you need to set a password for your account for
                    additional security.
                </p>
                <div className="textfield" ref={passwordTextField}>
                    <input
                        placeholder=" "
                        type="password"
                        value={password}
                        onChange={(e) => {
                            passwordTextField.current.querySelector("label").innerHTML =
                                "Password";
                            passwordTextField.current.classList.remove("error");
                            repeatPasswordTextField.current.classList.remove("error");
                            setPassword(e.target.value);
                        }}
                    />
                    <label>Password</label>
                </div>
                <div className="textfield" ref={repeatPasswordTextField}>
                    <input
                        placeholder=" "
                        type="password"
                        value={repeatPassword}
                        onChange={(e) => {
                            passwordTextField.current.querySelector("label").innerHTML =
                                "Password";
                            passwordTextField.current.classList.remove("error");
                            repeatPasswordTextField.current.classList.remove("error");
                            setRepeatPassword(e.target.value);
                        }}
                    />
                    <label>Repeat password</label>
                </div>
            </div>
            <button onClick={() => { setPasswordHandler(); }}>Next</button>
        </div>
    );
}
