import { memo, useCallback, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PageClose, PageHeader } from "../../Page";
import { Icon, Switch } from "../../common";
import { AuthContext, UserContext } from "../../../Auth/Auth";
import { DetectDevice, GetDeviceData } from "../../../Auth/Verify";
import { socket } from "../../../../App";
import DropdownMenu from "../../../UI/DropdownMenu";
import MenuItem from "../../../UI/MenuItem";
import Menu from "../../../UI/Menu";

function SettingsDevices() {
    const [sessions, setSessions] = useState([])

    const dispatch = useDispatch()

    const Auth = useContext(AuthContext);

    const terminateSession = (sessionId) => {
        socket.emit('TerminateSession', { token: Auth.authJWT, sessionId })
        socket.on('TerminateSession', (response) => {
            if (response.ok) {
                var data = sessions.filter(e => e._id !== sessionId)
                setSessions(data)
                socket.off('TerminateSession')
            }
        })
    }


    const GetOS = () => {
        const userAgent = window.navigator.userAgent,
            platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
            macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'];
        let os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = 'Mac OS';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = 'Windows';
        } else if (/Android/.test(userAgent)) {
            os = 'Android';
        } else if (/Linux/.test(platform)) {
            os = 'Linux';
        }

        return os;
    }

    useEffect(() => {
        setTimeout(() => {
            if (document.querySelector('.fadeThrough'))
                document.querySelector('.fadeThrough').classList.remove('fadeThrough')
        }, 100);
    }, [])

    useEffect(() => {
        const sessionId = Auth.device.sessionId

        socket.emit('GetUserSessions', { token: Auth.authJWT })
        socket.on('GetUserSessions', (response) => {
            if (response.ok) {
                var data = response.data.filter(e => e._id !== sessionId).reverse()
                setSessions(data)
                socket.off('GetUserSessions')
            }
        })
    }, [Auth.device])

    return <div className="SettingsDevices fadeThrough">
        <PageHeader>
            <div><Icon name="arrow_back" className="backBtn" onClick={() => PageClose(dispatch, true)} /></div>
            <div className="Title"><span>Devices</span></div>
            <div className="Meta"></div>
        </PageHeader>
        <div className="section ThisDevice">
            <div className="Items">
                <span className="title">This device</span>
                <div className="Item">
                    <Icon name={Auth.device.os === 'Android' ? "android" : 'laptop_windows'} />
                    <div className="FlexColumn">
                        <span className="title">{Auth.device.os} {Auth.device.osVersion}</span>
                        <span className="subtitle">{Auth.device.browserMajorVersion ? (Auth.device.browser + ' ' + Auth.device.browserMajorVersion) : Auth.device}</span>
                    </div>
                    <span className="Meta">{Auth.device.ipData && (Auth.device.ipData.city ? Auth.device.ipData.city + ', ' : '') + Auth.device.ipData.country_name}</span>
                </div>
                <div className="Item danger"><Icon name="front_hand" /><span>Terminate All Other Sessions</span></div>
            </div>
        </div >
        <div className="section ActiveSession">
            <div className="Items">
                <span className="title">Active sessions</span>
                {sessions.map((item, index) => (
                    <Menu key={index} animateWidth={false} custom={
                        <div className="Item">
                            <Icon name={item.device.os === 'Android' ? "android" : 'laptop_windows'} />
                            <div className="FlexColumn">
                                <span className="title">{item.device.os} {item.device.osVersion}</span>
                                <span className="subtitle">{item.device.browserMajorVersion ? (item.device.browser + ' ' + item.device.browserMajorVersion) : item.device}</span>
                            </div>
                            <span className="Meta">{item.device.ipData && (item.device.ipData.city ? item.device.ipData.city + ', ' : '') + item.device.ipData.country_name}</span>
                        </div>}>
                        <DropdownMenu className="CustomMenu">
                            <div className="Item">
                                <Icon name={item.device.os === 'Android' ? "android" : 'laptop_windows'} />
                                <div className="FlexColumn">
                                    <span className="title">{item.device.os} {item.device.osVersion}</span>
                                    <span className="subtitle">{item.device.browserMajorVersion ? (item.device.browser + ' ' + item.device.browserMajorVersion) : item.device}</span>
                                </div>
                                <span className="Meta">{item.device.ipData && item.device.ipData.city + ', ' + item.device.ipData.country_name}</span>
                            </div>
                            <MenuItem className="danger" title="Terminate Session" onClick={() => terminateSession(item._id)} />
                        </DropdownMenu>
                    </Menu>
                ))}
            </div>
        </div>
    </div >
}

export default memo(SettingsDevices)