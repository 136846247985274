import { CircularProgress, circularProgressClasses } from "@mui/material";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "../common";
import { IKImage } from "imagekitio-react";
import { urlEndpoint } from "../Home";
import Transition from "../Transition";
import { handleMediaPreview } from "../../Stores/UI";

const MessageMedia = forwardRef(({ media, data }, ref) => {
    const [size, setSize] = useState(16)
    const [progress, setProgress] = useState()
    const [isLoaded, setIsLoaded] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [waitForSave, setWaitForSave] = useState(false)
    const [src, setSrc] = useState(false)
    const [uploading, setUploading] = useState()
    // const messages = useSelector((state) => state.messages.value)
    // const message = messages[data.chatId].indexOf(data._id)
    const image = useRef()
    const messageMedia = useRef()

    const dispatch = useDispatch()

    useEffect(() => {
        messageMedia.current.style.height = media[0].height > 0 ? calculateMediaDimensions(media[0].width, media[0].height).height + 'px' : ''
    }, [])

    useEffect(() => {
        if (data.progress) {
            setProgress(data.progress)
            if (data.progress.loaded === data.progress.total) {
                setIsLoaded(true)
            }
        }
    }, [data.progress])

    useEffect(() => {
        if (isLoaded && waitForSave) {
            image.current.onSave()
            setWaitForSave(false)
        }
    }, [isLoaded, waitForSave])

    useEffect(() => {
        if (data.isUploading) {
            media[0].fileType = media[0].type.split('/')[0]

            var fr = new FileReader();

            fr.readAsDataURL(media[0]);

            fr.onload = function (e) {
                setUploading(this.result)
            };
        } else {
        }
    }, [media])

    const downloadMedia = () => {
        if (isDownloading) {
            image.current.onAbort()
            setSize(16)
            setIsDownloading(false)
        } else {
            // messageMedia.current.querySelector('img').transformation = {}
            setSize()
            setIsDownloading(true)
            try {
                image.current.onDownload()
            } catch (error) {

            }
        }
    }

    const previewMedia = () => {
        if (!isLoaded) {
            return;
        }

        const mediaSrc = src

        dispatch(handleMediaPreview({ message: data, media, mediaSrc }))
    }

    useImperativeHandle(ref, () => ({
        onSave() {
            if (isLoaded) {
                image.current.onSave()
            } else {
                if (!isDownloading) {
                    downloadMedia()
                    setWaitForSave(true)
                }
            }
        }
    }))

    const getMediaLayout = () => {
        if (media[0].fileType === 'image' || media[0].type === 'image') {
            return <Image ref={image} path={media[0].filePath} size={size} _width={media[0].width} _height={media[0].height} isLoaded={isLoaded} setIsLoaded={setIsLoaded} setProgress={setProgress} setSrc={setSrc} uploading={uploading} setIsDownloading={setIsDownloading} />
        } else {
            if (media[0].videoCodec) {
                switch (media[0].filePath.split('.').pop().toLowerCase()) {
                    case 'mp4':
                        break;
                    default:
                        break;
                }
                return <Video ref={image} path={media[0].filePath} size={size} width={media[0].width} height={media[0].height} isLoaded={isLoaded} setIsLoaded={setIsLoaded} setProgress={setProgress} setSrc={setSrc} setIsDownloading={setIsDownloading} autoplay={!media[0].audioCodec} />
            }
            return <Document ref={image} path={media[0].filePath} isLoaded={isLoaded} setIsLoaded={setIsLoaded} setProgress={setProgress} />
        }
    }

    return <div className="message-media" ref={messageMedia} onClick={previewMedia}>
        {getMediaLayout()}
        <Transition state={!isLoaded}>
            <div className="message-loading-progress" onClick={downloadMedia}>
                <Icon name={(isDownloading || data.isUploading) ? "close" : "arrow_downward"} size={28} />
                {(isDownloading || data.isUploading) && <CircularProgress variant="determinate" style={{ color: '#fff', animation: 'spinner 3s linear infinite' }} sx={{ [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' } }} thickness={3} size={48} value={progress ? (progress.loaded / progress.total) * 100 : 1} />}
            </div>
        </Transition>

    </div>
})

export default memo(MessageMedia)

const Document = forwardRef(({ path, setProgress, isLoaded, setIsLoaded }, ref) => {
    const url = `${urlEndpoint}${path}`
    const file = useRef()
    const request = useRef()

    useImperativeHandle(ref, () => ({
        onAbort() {
            console.log('Document aborted')
            request.current.abort()
        },
        onDownload() {
            var xmlHTTP = new XMLHttpRequest();
            xmlHTTP.open('GET', url, true);
            xmlHTTP.responseType = 'arraybuffer';
            xmlHTTP.onload = function (e) {
                var blob = new Blob([this.response]);
                if (file.current)
                    file.current.src = window.URL.createObjectURL(blob);
                setIsLoaded(true)
            };
            xmlHTTP.onprogress = function (e) {
                setProgress({ loaded: e.loaded, total: e.total })
            };
            xmlHTTP.onloadstart = function () {
                setProgress(1)
            };
            xmlHTTP.send();
            request.current = xmlHTTP
        }
    }))

    return <div className="Document">

    </div>
})

const MOBILE_SCREEN_NO_AVATARS_MESSAGE_EXTRA_WIDTH_REM = 4.5;
const MOBILE_SCREEN_MESSAGE_EXTRA_WIDTH_REM = 7;
const MESSAGE_MAX_WIDTH_REM = 29;
const MESSAGE_OWN_MAX_WIDTH_REM = 30;
const REM = 16
const isMobile = window.innerWidth < 480

let cachedMaxWidthOwn;
let cachedMaxWidth;
let cachedMaxWidthNoAvatar;

function getMaxMessageWidthRem() {
    const regularMaxWidth = MESSAGE_MAX_WIDTH_REM;
    if (!isMobile) {
        return regularMaxWidth;
    }

    const windowWidth = window.innerWidth

    // @optimization Limitation: changing device screen width not supported
    if (!cachedMaxWidthOwn) {
        cachedMaxWidthOwn = Math.min(
            MESSAGE_OWN_MAX_WIDTH_REM,
            windowWidth / REM - MOBILE_SCREEN_NO_AVATARS_MESSAGE_EXTRA_WIDTH_REM,
        );
    }
    if (!cachedMaxWidth) {
        cachedMaxWidth = Math.min(
            MESSAGE_MAX_WIDTH_REM,
            windowWidth / REM - MOBILE_SCREEN_MESSAGE_EXTRA_WIDTH_REM,
        );
    }
    if (!cachedMaxWidthNoAvatar) {
        cachedMaxWidthNoAvatar = Math.min(
            MESSAGE_MAX_WIDTH_REM,
            windowWidth / REM - MOBILE_SCREEN_NO_AVATARS_MESSAGE_EXTRA_WIDTH_REM,
        );
    }

    return cachedMaxWidth
}

const calculateMediaDimensions = (width, height) => {
    const aspectRatio = height / width;
    const availableWidthRem = getMaxMessageWidthRem() * 16;
    const calculatedWidth = Math.min(width, availableWidthRem);
    const availableHeight = 27 * 16;
    const calculatedHeight = Math.round(calculatedWidth * aspectRatio);

    if (calculatedHeight > availableHeight) {
        return {
            width: Math.round(availableHeight / aspectRatio),
            height: availableHeight,
        };
    }

    return {
        height: calculatedHeight,
        width: calculatedWidth,
    };
}

const Image = forwardRef(({ path, size, _width, _height, setProgress, isLoaded, setIsLoaded, setSrc, uploading, setIsDownloading }, ref) => {
    const [width, setWidth] = useState(_width)
    const [height, setHeight] = useState(_height)

    const aspectRatio = height / width
    const url = `${urlEndpoint}${size ? `/tr:h-${Math.round(size * aspectRatio)},w-${size}` : ''}${path}`
    const img = useRef()
    const isLowQualityLoaded = useRef(false)
    const request = useRef()

    const onClick = (e) => {
        const { x, y } = img.current.getBoundingClientRect()
    }

    useImperativeHandle(ref, () => ({
        onAbort() {
            console.log('Image aborted')
            request.current.abort()
        },
        onSave() {
            var link = document.createElement("a");
            link.download = 'image.jpg';
            link.href = img.current.src;
            link.click();
        }
    }))

    useEffect(() => {
        img.current.src = uploading

        img.current.onload = () => {
            setWidth(img.current.offsetWidth)
            setHeight(img.current.offsetHeight)
        }
    }, [uploading])

    useEffect(() => {
        if (isLowQualityLoaded.current && size === 16 || uploading)
            return
        var xmlHTTP = new XMLHttpRequest();
        xmlHTTP.open('GET', url, true);
        xmlHTTP.responseType = 'arraybuffer';
        xmlHTTP.onload = function (e) {
            var blob = new Blob([this.response]);
            if (img.current) {
                let src = window.URL.createObjectURL(blob)
                img.current.src = src;
                setSrc(src)
            }
            if (size !== 16)
                setIsLoaded(true)
            else
                isLowQualityLoaded.current = true
        };
        xmlHTTP.onprogress = function (e) {
            if (size === 16) return
            // console.log(parseInt((e.loaded / e.total) * 100))
            setProgress({ loaded: e.loaded, total: e.total })
        };
        xmlHTTP.onloadstart = function () {
            if (size === 16) return
            setProgress(1)
        };
        xmlHTTP.send();
        request.current = xmlHTTP
    }, [path, size])

    return <img ref={img} width={width > 0 ? calculateMediaDimensions(width, height).width : ''} className={isLoaded ? '' : 'blurred'} />
})

const Video = forwardRef(({ path, size, width, height, setProgress, isLoaded, setIsLoaded, setSrc, setIsDownloading, autoplay = false }, ref) => {
    const aspectRatio = height / width
    const url = `${urlEndpoint}${size ? `/tr:h-${Math.round(size * aspectRatio)},w-${size}` : ''}${path}`
    const img = useRef()
    const isLowQualityLoaded = useRef(false)
    const request = useRef()

    const onClick = (e) => {
        const { x, y } = img.current.getBoundingClientRect()


    }

    useImperativeHandle(ref, () => ({
        onAbort() {
            console.log('Image aborted')
            request.current.abort()
        }
    }))

    useEffect(() => {
        if (isLowQualityLoaded.current && size === 16)
            return
        var xmlHTTP = new XMLHttpRequest();
        xmlHTTP.open('GET', url, true);
        xmlHTTP.responseType = 'arraybuffer';
        xmlHTTP.onload = function (e) {
            var blob = new Blob([this.response]);
            if (img.current) {
                let src = window.URL.createObjectURL(blob)
                img.current.src = src;
                setSrc(src)
            }
            if (size !== 16)
                setIsLoaded(true)
            else
                isLowQualityLoaded.current = true
        };
        xmlHTTP.onprogress = function (e) {
            if (size === 16) return
            // console.log(parseInt((e.loaded / e.total) * 100))
            setProgress({ loaded: e.loaded, total: e.total })
        };
        xmlHTTP.onloadstart = function () {
            if (size === 16) return
            setProgress(1)
        };
        xmlHTTP.send();
        request.current = xmlHTTP
    }, [path, size])

    return <video ref={img} width={calculateMediaDimensions(width, height).width} height={calculateMediaDimensions(width, height).height} className={isLoaded ? '' : 'blurred'} autoPlay={isLoaded && autoplay} loop={autoplay} />
})