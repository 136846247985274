import { memo, useContext, useEffect } from "react";
import { viewChat } from "./ChatList";
import { ChatContext } from "./ChatContext";
import { Profile } from "./common";
import MessageText from "./MessageText";
import { UserContext } from "../Auth/Auth";
import MessageSeen from "./Message/MessageSeen";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton } from "@mui/material";

function Chat({ info }) {
    const User = useContext(UserContext);

    const dispatch = useDispatch()

    console.log('Chat Rerendered')

    const isOutMessage = info.lastMessage?.from?._id == User._id ? true : false;

    const ui = useSelector((state) => state.ui.value.activeChat)

    useEffect(() => {
        if (!info._id) return
        var i = 0
        // messages[info._id].forEach(item => {
        //     if (item.seen.indexOf(User._id) === -1)
        //         i++
        // })
    }, [info])

    return <div className={"Chat" + (ui?._id === info._id ? ' active' : '')} onClick={() => viewChat(info, dispatch)}>
        <div className="meta"><Profile name={info.firstname} id={getChatData(info)._id} /></div>
        <div className="body">
            <div className="info">
                <div className="title">{info.firstname}</div>
                <div className="message-details">
                    {isOutMessage && (
                        <MessageSeen data={info.lastMessage} />
                    )}
                    <div className="message-time">{getDateText(info.lastMessage.date)}</div>
                </div>
            </div>
            <div className="subtitle">
                <div className="last-message">{<MessageText data={info.lastMessage} includeFrom={info.type !== 'private'} />}</div>
            </div>
        </div>
    </div>
}

export const ChatsLoading = memo(function () {
    var divs = []
    for (let i = 0; i < 20; i++) {
        divs.push(
            <div className="Chat">
                <div className="meta">
                    <Skeleton variant="circular" animation={false} width={48} height={48}></Skeleton>
                </div>
                <div className="body">
                    <div className="info">
                        <div className="title">
                            <Skeleton variant="rounded" animation="wave" sx={{ borderRadius: '10px' }} width={Math.round(Math.random() * 50 + 50)} height={18} />
                        </div>
                        <div className="message-details">
                            <div className="message-time">
                                <Skeleton variant="rounded" animation="wave" sx={{ borderRadius: '10px' }} width={Math.round(Math.random() * 10 + 40)} height={10} />
                            </div>
                        </div>
                    </div>
                    <div className="subtitle">
                        <Skeleton variant="rounded" animation="wave" sx={{ borderRadius: '10px' }} width={Math.round(Math.random() * 200 + 100)} height={18} />
                    </div>
                </div>
            </div>)
    }
    return <>{divs}</>
})

export function getChatData(data) {
    if (data.type === 'private') {
        return data.to
    } else {
        return data
    }
}

function getDateText(date) {
    if (!date)
        return ''
    const _date = new Date(date)
    const now = new Date()
    if (_date.getFullYear() === now.getFullYear() || (_date.getMonth() > now.getMonth() && _date.getFullYear() + 1 === now.getFullYear())) {
        if (_date.getMonth() === now.getMonth()) {
            if (_date.getDate() === now.getDate() || (_date.getDate() + 1 === now.getDate() && _date.getHours() + 6 >= now.getHours())) {
                return `${_date.getHours().toLocaleString('en-US', { minimumIntegerDigits: 2 })}:${_date.getMinutes().toLocaleString('en-US', { minimumIntegerDigits: 2 })}`
            } else if (_date.getDate() >= now.getDate() - 7) {
                const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
                return weekday[_date.getDay()]
            }
        } else {
            const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            return `${months[_date.getMonth()]} ${_date.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2 })}`
        }
    } else {
        return `${_date.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2 })}.${(_date.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })}.${_date.getFullYear().toString().slice(2, 4)}`
    }
}

export default memo(Chat)