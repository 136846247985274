import { useEffect, useRef, useState } from "react"

export default function Transition({ state, action, activeAction, eachElement = false, children }) {
    const [isActive, setIsActive] = useState(false)

    const element = useRef()

    useEffect(() => {
        if (state) {
            setIsActive(true)
        } else if (isActive) {
            if (eachElement) {
                element.current.querySelectorAll('.Transition>*').forEach((item) => {
                    item.classList.add('animate')
                    item.classList.add('hideAnim')
                })
            } else {
                element.current.firstElementChild.classList.add('animate')
                element.current.firstElementChild.classList.add('hideAnim')
            }
            setTimeout(() => {
                setIsActive(false)
            }, 300);
        }
    }, [state])

    useEffect(() => {
        if (isActive) {
            if (activeAction)
                activeAction()
            requestAnimationFrame(() => {
                if (eachElement) {
                    const items = element.current.querySelectorAll('.Transition>*')

                    items.forEach((item) => {
                        item.classList.add('animate')
                        item.classList.add('showAnim')
                    })
                    requestAnimationFrame(() => {
                        items.forEach((item) => {
                            item.classList.remove('animate')
                            item.classList.remove('showAnim')
                        })
                    })
                } else {
                    element.current.firstElementChild.classList.add('animate')
                    element.current.firstElementChild.classList.add('showAnim')
                    requestAnimationFrame(() => {
                        element.current.firstElementChild.classList.remove('animate')
                        element.current.firstElementChild.classList.remove('showAnim')
                    });
                }
            })
        } else {
            if (action)
                action()
        }
    }, [isActive])

    return <>{isActive && <div className="Transition" ref={element}>
        {children}
    </div>}</>
}