import { Picker } from "emoji-mart";
import { memo, useCallback, useContext, useEffect, useRef, useState } from "react";
import ContentEditable from "../../common/WrappedContentEditable";
import { IKUpload } from "imagekitio-react";
import { Icon } from "../common";
import EmojiData from '@emoji-mart/data/sets/14/apple.json'
import { useDispatch, useSelector } from "react-redux";
import { handleMessageError, messageAdded, updateMessageId, updateMessageMediaUploadProgress, updateMessageText } from "../../Stores/Messages";
import { socket } from "../../../App";
import { setChat, updateLastMessage } from "../../Stores/Chats";
import { AuthContext, UserContext } from "../../Auth/Auth";
import { handleEditMessage, handleReplyToMessage } from "../../Stores/UI";
import MessageText from "../MessageText";
import { EmojiConvertor } from "emoji-js";

function Composer({ scrollToBottom, handleScrollToBottom }) {
    const [messageInput, setMessageInput] = useState("");
    const [messageInputHandled, setMessageInputHandled] = useState("");
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);

    const Auth = useContext(AuthContext)
    const User = useContext(UserContext)

    const chats = useSelector((state) => state.chats.value)
    const activeChat = useSelector((state) => state.ui.value.activeChat)
    const editMessage = useSelector((state) => state.ui.value.editMessage)
    const replyToMessage = useSelector((state) => state.ui.value.replyToMessage)

    const ikUploadRefTest = useRef()
    const messageInputEl = useRef();
    const placeholderRef = useRef();
    const sendButton = useRef();
    const attachButton = useRef();

    const dispatch = useDispatch()
    const emoji = new EmojiConvertor()

    const sendMessage = useCallback((isMedia) => {
        if (!messageInputHandled || messageInputHandled.trim().length === 0) {
            console.log('message is empty', messageInputHandled)
            return false;
        }
        const messageText = messageInputHandled.trim()
        if (editMessage) {
            // socket.emit('UpdateMessage', { token: Auth.authJWT, message: { _id: message._id, chatId: message.chatId, fromId: message.fromId, message: messageInputHandled } })
            socket.emit('UpdateMessage', { token: Auth.authJWT, message: { _id: editMessage._id, chatId: editMessage.chatId, fromId: editMessage.fromId, message: messageText } })
            socket.on('UpdateMessage', (response) => {
                if (response.ok) {
                    dispatch(handleEditMessage())
                    dispatch(updateMessageText({ _id: editMessage._id, chatId: editMessage.chatId, message: messageText }))
                    changeMessageInputHandler("");
                    socket.off('UpdateMessage')
                }
            })
            return true;
        }
        const message = {
            chatId: activeChat._id,
            date: Date.now(),
            forwardId: null,
            from: User,
            fromId: User._id,
            id: Date.now(),
            message: messageText,
            reply: replyToMessage ? replyToMessage : 0,
            seen: null,
            type: "text",
            messageType: "message",
        };

        if (activeChat._id == 0 && activeChat.type == 'private')
            message.toId = activeChat.toId

        dispatch(messageAdded(message));
        handleScrollToBottom()
        if (replyToMessage) dispatch(handleReplyToMessage());

        handleSendButtonAnimation()

        socket.emit('SendMessage', { token: Auth.authJWT, message })
        socket.on('SendMessage', (response) => {
            if (response.ok) {
                // message._id = response.data;
                dispatch(updateMessageId({ messageId: message.id, chatId: message.chatId, _id: response.data }))
                dispatch(updateLastMessage({ _id: message.chatId, message: { ...message, _id: response.data, seen: [] } }))
                // dispatch(messageAdded((current) => [...current]));
                socket.off('SendMessage')
            } else {
                dispatch(handleMessageError({ messageId: message.id, chatId: message.chatId }))
            }
        })
    }, [messageInputHandled]);

    const changeMessageInputHandler = useCallback(e => {
        if (e === null) e = ''
        var value = e.target ? e.target.value : e;

        emoji.replace_mode = 'img';
        emoji.img_sets.apple.path = 'https://cdnjs.cloudflare.com/ajax/libs/emoji-datasource-apple/15.0.1/img/apple/64/'
        emoji.allow_native = true;
        emoji.include_title = true
        var output = emoji.replace_colons(value);
        emoji.colons_mode = true
        var input = emoji.replace_unified(value)
        input = input.replaceAll(/<img.*?title="(.*?)"(\/?)>/g, ":$1:")

        setMessageInputHandled(input)
        setMessageInput(output);

        if (value !== "") {
            placeholderRef.current
                .classList.add("hidden");

            if (sendButton.current.children[0].innerHTML !== "send") {
                sendButton.current.children[0].classList.add("animate");
                attachButton.current.querySelector('.icon').classList.add("hidden");
                setTimeout(() => {
                    attachButton.current.style.display = "none";
                }, 300);
                setTimeout(() => {
                    sendButton.current.children[0].innerHTML = "send";
                    sendButton.current.children[0].classList.remove("animate");
                }, 150);
            }
        } else {
            placeholderRef.current
                .classList.remove("hidden");
            sendButton.current.children[0].classList.add("animate");
            attachButton.current.style = "";
            setTimeout(() => {
                attachButton.current.querySelector('.icon').classList.remove("hidden");
            }, 0);
            setTimeout(() => {
                sendButton.current.children[0].innerHTML = "mic";
                sendButton.current.children[0].classList.remove("animate");
            }, 150);
        }
    }, [messageInput]);

    useEffect(() => {
        if (replyToMessage || editMessage) {
            setTimeout(() => {
                document.querySelector(".PreviewMessage").classList.remove("animate");
                setTimeout(() => {
                    scrollToBottom.current.classList.add('hidden')
                }, 300);
            }, 0);
        }
        if (editMessage) {
            changeMessageInputHandler(editMessage.message);
            setTimeout(() => {
                messageInputEl.current.firstElementChild.focus();
            }, 10);
        }
    }, [replyToMessage, editMessage]) // replyToMessage and editMessage transition

    const handleSendButtonAnimation = () => {
        sendButton.current.firstElementChild.style =
            "transition:all .15s ease-out;";
        sendButton.current.firstElementChild.style.marginRight = "-42px";
        setTimeout(() => {
            changeMessageInputHandler("");
            setTimeout(() => {
                sendButton.current.firstElementChild.style.cssText +=
                    "transition:transform .3s ease;transform:scale(.9)";
                setTimeout(() => {
                    sendButton.current.firstElementChild.style.marginRight = "4px";
                    setTimeout(() => {
                        sendButton.current.firstElementChild.style = "";
                    }, 40);
                }, 100);
            }, 75);
        }, 100);
        messageInputEl.current.children[0].focus();
    }

    const handleMessageInput = useCallback(() => {
        emoji.colons_mode = true
        var input = emoji.replace_unified(messageInput)
        input = input.replaceAll(/<img.*?title="(.*?)"(\/?)>/g, ":$1:")
        setMessageInputHandled(input)
    }, [messageInput])

    const htmlDecode = (input) => {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    const handleKeyDown = (event) => {
        setTimeout(() => {
            // changeMessageInputHandler(event);
            // handleMessageInput()
        }, 0);
        if (event.key === "Enter") {
            event.preventDefault();
            handleMessageInput()
            sendMessage();
        }
    };

    const onJoinGroup = () => {
        dispatch(setChat(activeChat))
    }

    const handleJoinGroup = useCallback(() => {
        socket.emit('JoinGroup', { token: Auth.authJWT, chatId: activeChat._id })
        socket.on('JoinGroup', onJoinGroup)
        return () => socket.off('JoinGroup', onJoinGroup);
    }, [User, activeChat])

    const onUploadMedia = (e) => {
        const message = {
            chatId: activeChat._id,
            date: Date.now(),
            forwardId: null,
            from: User,
            fromId: User._id,
            id: Date.now(),
            media: e.target.files,
            message: htmlDecode(messageInputHandled),
            reply: replyToMessage ? replyToMessage : 0,
            seen: null,
            isUploading: true,
            type: "media",
            messageType: "message",
        };

        ikUploadRefTest.current.message = message
        dispatch(messageAdded(message));
        handleScrollToBottom()
    }

    const onUploadMediaProgress = (e) => {
        var message = ikUploadRefTest.current.message;
        message.media[0].progress = { loaded: e.loaded, total: e.total }

        dispatch(updateMessageMediaUploadProgress(message));
    }

    const onUploadMediaSuccess = (e) => {
        var message = ikUploadRefTest.current.message

        message = { ...message, media: [e], isUploading: undefined }

        handleSendButtonAnimation()

        socket.emit('SendMessage', { token: Auth.authJWT, message })
        socket.on('SendMessage', (response) => {
            if (response.ok) {
                dispatch(updateMessageId({ messageId: message.id, chatId: message.chatId, _id: response.data }))
                dispatch(updateLastMessage({ _id: message.chatId, message: { ...message, _id: response.data, seen: [] } }))

                socket.off('SendMessage')
            }
        })
    }

    return <>
        {replyToMessage && (
            <div className="PreviewMessage animate">
                <Icon name="reply" className="meta" />
                <div className="body">
                    <div className="title">Reply message</div>
                    <div className="subtitle">
                        <MessageText data={replyToMessage} includeFrom />
                    </div>
                </div>
                <div
                    className="close icon"
                    onClick={() => dispatch(handleReplyToMessage())}
                >
                    close
                </div>
            </div>
        )}
        {editMessage && (
            <div className="PreviewMessage animate">
                <Icon name="edit" className="meta" />
                <div className="body">
                    <div className="title">Edit message</div>
                    <div className="subtitle"><MessageText data={editMessage} /></div>
                </div>
                <div
                    className="close icon"
                    onClick={() => {
                        dispatch(handleEditMessage())
                        changeMessageInputHandler("");
                    }}
                >
                    close
                </div>
            </div>
        )}
        {Object.values(chats).findIndex(item => item._id === activeChat._id) === -1 && activeChat.type !== 'private' ? <>
            <div className="Button" onClick={handleJoinGroup}>Join Group</div>
        </> : <>
            <div className="Composer">
                <div className="emoji-button" onClick={() => setShowEmojiPicker(!showEmojiPicker)}>
                    <div className="icon">mood</div>
                </div>
                {showEmojiPicker && <div style={{ position: 'absolute', bottom: 50 }}>
                    <Picker onEmojiSelect={(e) => { changeMessageInputHandler(messageInput + e.shortcodes) }} theme="dark" set="apple" previewPosition="none" data={EmojiData} />
                </div>}
                <div className="message-input" ref={messageInputEl}>
                    {activeChat.permissions.sendText ? <>
                        <ContentEditable
                            dir="auto"
                            className="input"
                            html={messageInput} // innerHTML of the editable div
                            disabled={false}       // use true to disable editing
                            onChange={changeMessageInputHandler} // handle innerHTML change
                            onBlur={handleMessageInput}
                            onKeyDown={handleKeyDown}
                            tagName='span' // Use a custom HTML tag (uses a div by default)
                        />
                        <span className="placeholder" ref={placeholderRef}>Message</span>
                    </> : <><Icon name="lock" /><span className="disabled">Text not allowed</span></>}
                    <div className="attach-button" ref={attachButton}>
                        <IKUpload
                            isPrivateFile={false}
                            useUniqueFileName={true}
                            validateFile={file => file.size < 20000000}
                            overwriteFile={true}
                            onError={console.log}
                            onSuccess={onUploadMediaSuccess}
                            onUploadProgress={onUploadMediaProgress}
                            onUploadStart={onUploadMedia}
                            // style={{display: 'none'}} // hide the default input and use the custom upload button
                            ref={ikUploadRefTest}
                        />
                        <Icon name="attachment" size="28" />
                    </div>
                </div>
                <div
                    className="send-button"
                    ref={sendButton}
                    onClick={() => {
                        sendMessage();
                    }}
                >
                    <span className="icon">mic</span>
                </div>
            </div>
        </>}
    </>
}

export default memo(Composer)